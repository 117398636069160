import React from "react";
import VerifyEmailPage from "../react/pages/authentication/verify-email/";
import { Providers } from "../react/common/layouts/Layout";

export default function VerifyEmailGatsbyEntry(props) {
  return (
    <Providers>
      <VerifyEmailPage {...props} />
    </Providers>
  );
}
