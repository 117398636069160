import { navigate } from "gatsby";
import axios from "axios";
import _get from "lodash.get";
import queryString from "query-string";
import React, { useEffect } from "react";
import { object, string } from "yup";
import { isBrowser } from "../../../../utils";
const paramSchema = object().shape({
  id: string().required(),
  token: string().required()
});

export default function ForgotPasswordPage(): React.ReactElement {
  if (isBrowser() === false) {
    return null;
  }

  const queryParams = queryString.parse(location.search);

  try {
    paramSchema.validateSync(queryParams);
  } catch (e) {
    navigate("/login");
  }

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const response = await axios({
          url: `${process.env.GATSBY_PWS_API_URL}${process.env.GATSBY_VERIFY_EMAIL_URL}`,
          method: "POST",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json"
          },
          data: queryParams
        });

        const authToken = _get(response, "data.response.token", false);

        if (!authToken) {
          navigate("/login", {
            state: {
              flash: "Thank you for verifying your email. You can now log in to your account."
            }
          });
        }

        window.location.assign(
          `${process.env.GATSBY_PROD_URL}${process.env.GATSBY_STATION_LIST_URL}?authToken=${authToken}&toast=emailVerified`
        );
      } catch (error) {
        const code = _get(error, "response.data.error.code", null);
        if (code === "token_expired_error") {
          navigate("/login", {
            state: {
              flash:
                "Your verification email has expired. A new email has been sent to your email address. Please follow the instructions in the email to activate your account."
            }
          });
          return;
        }
        navigate("/login");
      }
    };
    makeRequest();
  });

  return <></>;
}
